/**
 * 筛选一些公共事件事件中心
 */

import { throttle } from '@shein/common-function'
import { sleep } from 'public/src/pages/components/FilterBar/utils/index.js'
import { isDisabledScroll } from 'public/src/pages/components/FilterBar/utils/disabledScroll.js'

// #region 当前dropdown1展开时，点击dropdown2，需要关闭dropdown1
export const CLOSE_DROPDOWN = 'closeDropdown'
let dropdownInstance = null
export function getDropdownInstance () {
  return dropdownInstance
}
export function setDropdownInstance (instance) {
  dropdownInstance = instance
}
export function emitCloseDropdown () {
  window.appEventCenter.$emit(CLOSE_DROPDOWN)
}
export function onCloseDropdown (callback) {
  window.appEventCenter.$on(CLOSE_DROPDOWN, callback)
}
export function offCloseDropdown () {
  window.appEventCenter.$off(CLOSE_DROPDOWN)
}
// #endregion

// #region 更新panel高度
const UPDATE_DROPDOWN_PANEL_HEIGHT = 'updateDropdownPanelHeight'
export async function emitUpdateDropdownPanelHeight (loopTime = 0) {
  // 兼容url输入框在顶部body fixed下，document.documentElement.scrollTop 不为0的情况导致的高度计算错误
  (document?.body?.style?.position && document.body.style.position === 'fixed') && (document.documentElement.scrollTop === 0) 
  window.appEventCenter.$emit(UPDATE_DROPDOWN_PANEL_HEIGHT)
  if (loopTime === 0) return Promise.resolve()

  sleep(loopTime, () => window.appEventCenter.$emit(UPDATE_DROPDOWN_PANEL_HEIGHT))
}

export function onUpdateDropdownPanelHeight (callback) { 
  window.appEventCenter.$on(UPDATE_DROPDOWN_PANEL_HEIGHT, callback)
}
export function offUpdateDropdownPanelHeight () {
  window.appEventCenter.$off(UPDATE_DROPDOWN_PANEL_HEIGHT)
}

// #endregion

// #region CloudTag

// #endregion


// #region kids tag
const CLOSE_KID_TAG_POPOVER = 'closeKidTagPopover'
export function emitCloseKidTagPopover () {
  window.appEventCenter.$emit(CLOSE_KID_TAG_POPOVER)
}
export function onCloseKidTagPopover (callback) {
  window.appEventCenter.$on(CLOSE_KID_TAG_POPOVER, callback)
}
export function offCloseKidTagPopover () {
  window.appEventCenter.$off(CLOSE_KID_TAG_POPOVER)
}
// #endregion
const _getElHeight = ($el) => {
  return $el ? $el.offsetHeight : 0
}

const _resetElZIndex = ($el, zIndex) => { 
  if (!$el) {
    return
  }
  $el.style.zIndex = zIndex
  $el.style.transform = `translate3d(0, 0, ${zIndex}px)`
}

const _resetElTop = ($el, topVal) => {
  if (!$el) {
    return
  }
  if ($el.dataset.isConfigSticky === 'true') {
    $el.style.top = Number($el.dataset.stickyTop) + 'px'
  } else {
    $el.style.top = topVal + 'px'
    _resetElZIndex($el, 3)
  }
}

const hasSmallPic = ($picTopNav) => {
  // 图文导航tab模式高度约为43，小图圆形模式约为48，小图方形模式约为63
  if (Number($picTopNav?.dataset?.stickyHeight) > 52) {
    return true
  }
  return false
}

const _getDom = () => {
  const $commonHeader = document.querySelector('.j-common-header-dom')
  const $picTopNav = document.querySelector('#scroll-top-pic-top-nav')
  const $navBar = document.querySelector('#scroll-top-nav-bar')
  const $cloudTags = document.querySelector('#scroll-top-cloud-tags')
  const $oneClick = document.querySelector('#scroll-top-one-click-pay')
  const $policy = document.querySelector('#scroll-top-policy-banner')

  return {
    $commonHeader,
    $picTopNav,
    $navBar,
    $cloudTags,
    $oneClick,
    $policy,
  }
}

// 新筛选
const scrollTopHandler = () => {

  let lastScrollY = window.scrollY
  let record = 0

  const $picItems = document.querySelector('#scroll-top-pic-top-nav')?.querySelectorAll('.content-item') || []
  Array.from($picItems).forEach($item => {
    $item.addEventListener('click', () => {
      if ($item.classList.contains('content-item__small')) {
        const {
          $navBar,
          $cloudTags,
          $oneClick,
          $policy,
        } = _getDom()
    
        record = 0
        _resetElTop($navBar, -20)
        _resetElTop($cloudTags, -20)
        _resetElTop($oneClick, -20)
        _resetElTop($policy, -20)
      }
    })
  })

  return () => {
    const scrollY = window.scrollY
    const isScrollDown = scrollY > lastScrollY
    const distance = scrollY - lastScrollY // 滚动了多少距离
    // 当点击标签云时，会触发滚动，该处理是为了兼容点击标签云
    if (scrollY > 10) {
      lastScrollY = scrollY
    }

    const {
      $commonHeader,
      $picTopNav,
      $navBar,
      $cloudTags,
      $oneClick,
      $policy,
    } = _getDom()
    
    // 切换页面时，滚动到顶部，重置元素位置
    if (isDisabledScroll()) {
      return
    }
    
    // 判断最后一个元素是否跟下一个交叉，交叉则需要重新计算
    const targetDom = $policy || $oneClick || $cloudTags || $navBar || $picTopNav || $commonHeader
    const nextDom = targetDom.nextElementSibling
    if (targetDom?.getBoundingClientRect?.()?.bottom - nextDom?.getBoundingClientRect?.()?.top < 10) {
      _resetElTop($picTopNav, -_getElHeight($picTopNav))
      _resetElTop($navBar, -20)
      _resetElTop($cloudTags, -20)
      _resetElTop($oneClick, -20)
      _resetElTop($policy, -20)
      return
    }
    // const baseHeight = _getElHeight($commonHeader) + _getElHeight($picTopNav) +
    //   _getElHeight($navBar) + _getElHeight($cloudTags) +
    //   _getElHeight($policy) + _getElHeight($oneClick)

    // 去除该判断，在2行图文下，baseHeight > 300px, 会导致误判
    // if (scrollY < baseHeight) {
    //   // 刚开始时自然滚动，无需处理
    //   return
    // }

    // 滚动距离增加
    if (isScrollDown) {
      record = 0
      const picTopNavHeight = $picTopNav?.getBoundingClientRect?.()?.height || 100
      _resetElTop($picTopNav, -(picTopNavHeight - 30))
      _resetElTop($navBar, -20)
      _resetElTop($cloudTags, -20)
      _resetElTop($oneClick, -20)
      _resetElTop($policy, -20)
      return
    }

    // 滚动距离减少
    record += distance

    // 误差处理，防止意外展示顶部元素
    if (record > -70 || record < -1000) {
      return
    }

    const isReset = scrollY < 1 // 滚动距离小于2时，重置元素位置
    const num = hasSmallPic($picTopNav) ? 24 : 14

    if ($oneClick) {
      $oneClick.style.top = _getElHeight($commonHeader) + _getElHeight($picTopNav) + _getElHeight($navBar) + _getElHeight($cloudTags) - num + 'px'
      _resetElZIndex($oneClick, 12)
    }

    if ($policy) {
      $policy.style.top = _getElHeight($commonHeader) + _getElHeight($picTopNav) + _getElHeight($navBar) + _getElHeight($cloudTags) - num + 'px'
      _resetElZIndex($policy, 12)
    }

    if ($cloudTags) {
      let top = isReset
        ? ($cloudTags?.dataset.isConfigSticky === 'true' ? Number($cloudTags?.dataset.stickyTop) : 0 + 'px')
        : (_getElHeight($commonHeader) + _getElHeight($picTopNav) + _getElHeight($navBar) - num + 'px')
      $cloudTags.style.top = top
      _resetElZIndex($cloudTags, $cloudTags?.dataset.isConfigSticky == 'true' ? 13 : 10)
    }

    if ($navBar) {
      let top = isReset
        ? ($navBar?.dataset.isConfigSticky === 'true' ? Number($navBar?.dataset.stickyTop) : 0 + 'px')
        : (_getElHeight($commonHeader) + (hasSmallPic($picTopNav) ? _getElHeight($picTopNav) - 12 : _getElHeight($picTopNav) - 4) + 'px')
      $navBar.style.top = top
      _resetElZIndex($navBar, 11)
    }

    if ($picTopNav) {
      let top = isReset
        ? ($picTopNav?.dataset.isConfigSticky === 'true' ? Number($picTopNav?.dataset.stickyTop) : 0 + 'px')
        : (_getElHeight($commonHeader) + 'px')
      $picTopNav.style.top = top
      _resetElZIndex($navBar, 12)
    }
  }
}

// 旧筛选，如daily-new.html
const oldScrollTopHandler = () => {

  let lastScrollY = window.scrollY

  return () => {
    const scrollY = window.scrollY
    const isScrollDown = scrollY > lastScrollY
    const distance = scrollY - lastScrollY
    if (scrollY > 10) {
      lastScrollY = scrollY
    }
    const $navBar = document.querySelector('#scrollfixed-filter-navbar')
    const $cloudTags = document.querySelector('#scrollfixed-cloud-tags')

    const baseHeight = + _getElHeight($navBar) + _getElHeight($cloudTags)

    if (document.body.style.overflow !== 'hidden' && scrollY < baseHeight) {
      if ($cloudTags) {
        $cloudTags.style.position = 'static'
      }
      return
    }
    if ($cloudTags) {
      $cloudTags.style.transition = 'all 0.3s'
    }

    // 滚动距离增加
    if (isScrollDown) {
      if ($cloudTags) {
        $cloudTags.style.position = 'static'
      }
      _resetElTop($cloudTags, -20)
      return
    }
    if (distance > -60 || distance < -300) {
      return
    }

    // 滚动距离减少
    if ($cloudTags) {
      $cloudTags.style.position = 'fixed'
      $cloudTags.style.top = _getElHeight($navBar) + 'px'
      _resetElZIndex($cloudTags, 11)
    }
  }
}

// 当往回滚动一定距离时，需展示显示顶部元素，如筛选栏、标签云
export const handleScrollExpand = ({ vm }) => {
  const oldHandler = oldScrollTopHandler()
  const newHandler = scrollTopHandler()
  
  const callback = () => {
    // daily-new切真实列表，不会触发activated
    document.querySelector('#product-list-filter-wrapper') ? oldHandler() : newHandler()
  }
  const scrollFn = throttle({
    func: callback,
    wait: 100,
    options: {
      trailing: true,
    }
  })

  window.addEventListener('scroll', scrollFn)

  vm.$once('hook:beforeDestroy', () => {
    window.removeEventListener('scroll', scrollFn)
  })

  vm.$watch(() => vm.Request?.type, (newType) => {
    // 列表切列表
    if (newType === 'firstload') {
      const {
        $navBar,
        $cloudTags,
      } = _getDom()
      if ($cloudTags) {
        $cloudTags.style.top = '0px'
      }
      if ($navBar) {
        $navBar.style.top = '0px'
      }
    }
  })

  return () => {
    window.removeEventListener('scroll', scrollFn)
  }
}
